import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const Component = () => {
  return (
    <div className='component-fixed-bg'>
      <StaticImage
        className='image-desktop'
        src='../images/banner/blue-bg.jpg'
        formats={['png']}
        alt=''
      />
      <StaticImage
        className='image-mobile'
        src='../images/banner/blue-bg-mobile.jpg'
        formats={['png']}
        alt=''
      />
    </div>
  )
}
export default Component
