import React from 'react'
import Menu from 'components/Menu'
import FixedBg from 'components/FixedBg'
import 'styles/styles.scss'
import { useLocalization } from 'gatsby-theme-i18n'

const Layout = (props) => {
  const { children, location } = props
  const { locale } = useLocalization()
  return <Inner children={children} location={location} locale={locale} />
}

class Inner extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { children, location, locale } = this.props
    return (
      <div id='layout'>
        <FixedBg />
        <Menu location={location} />
        <div id='container' className={`lan-${locale}`}>
          {children}
        </div>
      </div>
    )
  }
}

export default Layout
