import React from 'react'
import { Link } from 'gatsby'
import { useLocalization } from 'gatsby-theme-i18n'
import { lanLinks } from 'js/translations.js'

const LanLink = () => {
  const { locale } = useLocalization()
  const otherLanLink = locale === 'en' ? lanLinks.zh : lanLinks.en
  return (
    <Link to={otherLanLink.urlBase + '/'}>
      <div className='inner'>{otherLanLink.title.toUpperCase()}</div>
    </Link>
  )
}

export default LanLink
