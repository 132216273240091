exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-dr-moshe-feldenkrais-js": () => import("./../../../src/pages/about/dr-moshe-feldenkrais.js" /* webpackChunkName: "component---src-pages-about-dr-moshe-feldenkrais-js" */),
  "component---src-pages-about-feldenkrais-method-js": () => import("./../../../src/pages/about/feldenkrais-method.js" /* webpackChunkName: "component---src-pages-about-feldenkrais-method-js" */),
  "component---src-pages-about-greater-china-feldenkrais-association-js": () => import("./../../../src/pages/about/greater-china-feldenkrais-association.js" /* webpackChunkName: "component---src-pages-about-greater-china-feldenkrais-association-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-training-js": () => import("./../../../src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */),
  "component---src-templates-resource-post-js": () => import("./../../../src/templates/resource-post.js" /* webpackChunkName: "component---src-templates-resource-post-js" */),
  "component---src-templates-training-post-js": () => import("./../../../src/templates/training-post.js" /* webpackChunkName: "component---src-templates-training-post-js" */)
}

