import React from 'react'
import { Link } from 'gatsby'
import { LocaleContext } from 'gatsby-theme-i18n'
import { lanLinks } from 'js/translations.js'

const LinkWrap = ({
  to,
  className = '',
  activeClassName = '',
  children,
  bypassLocalization,
}) => {
  const locale = React.useContext(LocaleContext)
  const thisLanLink = lanLinks[locale]
  let setTo = to
  if (!bypassLocalization) setTo = thisLanLink.urlBase + to
  if (to) {
    return (
      <Link
        to={setTo}
        className={className}
        activeClassName={activeClassName}
        partiallyActive={to !== '/'}
      >
        {children}
      </Link>
    )
  } else {
    return <a className={`empty ${className}`}>{children}</a>
  }
}

export default LinkWrap
