const translations = {
  NOT_FOUND_404: { en: '404: not found', zh: '404: 未找到页面' },
  ABOUT: { en: 'About', zh: '关于' },
  CONTACT: { en: 'Contact', zh: '联系' },
  CONTACT_US: { en: 'Contact Us', zh: '联系我们' },
  HOME: { en: 'Home', zh: '首页' },
  MORE: { en: 'more »', zh: '更多 »' },
  READ_MORE: { en: 'Read More', zh: '了解更多' },
  VIEW_ALL: {
    en: 'View All',
    zh: '浏览全部',
  },
  SEO_COMPANY: {
    en: 'Greater China Feldenkrais Association',
    zh: '大中华菲登奎斯协会',
  },
  Q_A_TITLE: {
    en: `Q & A - Feldenkrais Shanghai`,
    zh: `关于将在上海举办的基于Moshe Feldenkrais教义的4年培训项目的问题解答`,
  },
  TEACHERS: {
    en: `Teachers`,
    zh: `导师`,
  },
  BACK_TO_ALL_TRAININGS: {
    en: 'back to all trainings',
    zh: '返回所有培训',
  },
  BACK_TO_ALL_RESOURCES: {
    en: 'back to all resources',
    zh: '返回所有资源',
  },
}

export const tr = (key, locale) => {
  let match = '[translation not found]'
  if (translations[key]) {
    match = translations[key][locale]
  }
  return match
}

export const lanLinks = {
  en: {
    locale: 'en',
    title: 'en',
    urlBase: '',
  },
  zh: {
    locale: 'zh',
    title: '中文',
    urlBase: '/zh',
  },
}
