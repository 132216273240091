import React from 'react'
import { useInView } from 'react-intersection-observer'

const Component = ({
  children,
  id = '',
  className,
  triggerOnce = false,
  onClick = null,
}) => {
  const [ref, inView] = useInView({
    triggerOnce,
  })
  return (
    <div
      id={id}
      className={`
    ${inView ? ' in-view' : ''}
    ${className ? className : ''}
    `}
      ref={ref}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

export default Component
