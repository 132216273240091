import React from 'react'
import ImagePng from 'components/ImagePng'
import LinkWrap from 'components/LinkWrap'
import LanLink from 'components/LanLink'
import { useLocalization } from 'gatsby-theme-i18n'
import Inview from 'components/Inview'

const Menu = ({ location }) => {
  const { locale } = useLocalization()
  return <Inner location={location} locale={locale} />
}

export default Menu

class Inner extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
    }
    this.menuToggle = this.menuToggle.bind(this)
    this.menuClose = this.menuClose.bind(this)
  }

  menuToggle() {
    this.setState((prevState) => ({
      menuOpen: !prevState.menuOpen,
    }))
  }

  menuClose() {
    this.setState({
      menuOpen: false,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.menuClose()
    }
  }

  render() {
    const { locale, location } = this.props

    let menu = {
      en: [
        {
          slug: 'about',
          text: 'About',
          customLink: 'about/greater-china-feldenkrais-association',
          sub: [
            {
              slug: 'greater-china-feldenkrais-association',
              text: 'Greater China Feldenkrais Association',
            },
            {
              slug: 'feldenkrais-method',
              text: 'The Feldenkrais Method',
            },
            {
              slug: 'dr-moshe-feldenkrais',
              text: 'Dr Moshe Feldenkrais',
            },
          ],
        },
        {
          slug: 'training',
          text: 'Training',
        },
        {
          slug: 'resources',
          text: 'Resources',
        },
        {
          slug: 'contact',
          text: 'Contact',
        },
      ],
      zh: [
        {
          slug: 'about',
          text: '关于',
          customLink: 'about/greater-china-feldenkrais-association',
          sub: [
            {
              slug: 'greater-china-feldenkrais-association',
              text: '大中华菲登奎斯协会',
            },
            {
              slug: 'feldenkrais-method',
              text: '菲登奎斯方法',
            },
            {
              slug: 'dr-moshe-feldenkrais',
              text: 'Moshe Feldenkrais 博士',
            },
          ],
        },
        {
          slug: 'training',
          text: '培训',
        },
        {
          slug: 'resources',
          text: '资源',
        },
        {
          slug: 'contact',
          text: '联系我们',
        },
      ],
    }
    menu = menu[locale]

    return (
      <Inview
        id='menu'
        className={`
            ${this.state.menuOpen ? ' open stick' : ''}
            fade-in
            `}
        triggerOnce={true}
      >
        <div
          className='thumb'
          onClick={this.menuToggle}
          onKeyDown={this.menuToggle}
          role='button'
          tabIndex='0'
        >
          <div className='one'></div>
          <div className='two'></div>
          <div className='three'></div>
        </div>
        <Wrap menu={menu} menuClose={this.menuClose} />
        <Wrap menu={menu} addClass='prefetch' />
      </Inview>
    )
  }
}

const Wrap = ({ menu, addClass, menuClose }) => {
  return (
    <div className={`loaded-wrap ${addClass}`}>
      <div className='inner'>
        <LinkWrap className='logo' to='/'>
          <ImagePng className='standard' filename='logo.png' alt='logo' />
        </LinkWrap>
        <ul>
          {menu.map((m0, i) => {
            return (
              <li key={i}>
                <LinkWrap
                  to={`/${m0.customLink ? m0.customLink : m0.slug}`}
                  activeClassName='active'
                  partiallyActive={true}
                  className={
                    m0.customLink ===
                      'about/greater-china-feldenkrais-association' &&
                    typeof location !== 'undefined' &&
                    location.pathname.includes('about/')
                      ? 'active'
                      : ''
                  }
                >
                  <div className='inner'>{m0.text}</div>
                </LinkWrap>
                {m0.sub && (
                  <ul className='sub'>
                    {m0.sub.map((m1) => {
                      return (
                        <li key={m1.slug}>
                          <LinkWrap
                            to={`/${m0.slug}/${m1.slug}`}
                            activeClassName='active'
                          >
                            {m1.text}
                          </LinkWrap>
                        </li>
                      )
                    })}
                  </ul>
                )}
              </li>
            )
          })}
          <li>
            <LanLink />
          </li>
        </ul>
      </div>
    </div>
  )
}
